/* Add here all your CSS customizations */
@import url('https://fonts.googleapis.com/css2?family=Bodoni+Moda:ital,wght@0,400;0,600;0,700;0,800;0,900;1,400;1,600;1,700;1,800;1,900&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

html:not(.sticky-header-active) #header.header-transparent:not(.header-semi-transparent) .header-body{
    background-color: #FFF !important;
}
html .bg-color-dark, html .bg-dark{
    background-color: #FFF !important;
}
@media (min-width: 991px){
    #header .header-nav.header-nav-links.header-nav-light-text nav > ul > li > a, #header .header-nav.header-nav-line.header-nav-light-text nav > ul > li > a{
        color: #212529;
    }
    #header .header-nav-main.header-nav-main-square nav > ul > li:hover > a{
        color: #212529 !important;
    }
}

html #header.header-transparent .header-body:not(.header-border-bottom){
    border-bottom: 1px solid #f57f22;
}
.rec.rec-dot {
    display: none !important;
}

.servicess-1{
    /* border-right: 2px solid #000; */
    border-left: 2px solid #000;
}

.services-1{
    border-right: 2px solid #000;
    border-left: 2px solid #000;
}

.border-left{
    border-left: 2px solid #000 !important;
}

.font-mon{
    font-family: 'Montserrat', sans-serif;
}

.c-button{
    font-family: 'Montserrat', sans-serif !important;
    padding: 10px !important;
    font-size: 10pt !important;
}

body a{
    font-family: 'Montserrat', sans-serif !important;
}

p{
    font-family: 'Montserrat', sans-serif !important;
    font-weight: 400;
    color: #000 !important;
}

.custom-call-to-action{
    border: none !important;
}

.divider-cta{
    border-left: 2px solid #fff;
    height: 138px;
    position: absolute;
    left: 45%;
}
.looking-for-box{
    text-align: left;
}

.pagination {
    display: inline-block;
}

.pagination a {
    color: black;
    float: left;
    padding: 8px 16px;
    text-decoration: none;
}

.pagination a.active {
    background-color: #f57f22;
    color: white;
}

.pagination a:hover:not(.active) {background-color: #ddd;}

.lang{
    opacity: 40%;
    cursor: pointer;
}
.lang.active-lang{
    opacity: 100% !important;
}

.lang:hover{
    opacity: 100%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.font-white{
    color: #FFF !important;
}

.line-camp{
    -moz-box-orient: vertical;
    overflow: hidden;
    white-space: pre-wrap;
    display: -webkit-box;
    -webkit-line-clamp: 1;
}

@media (max-width:499px){
    .divider-cta{
        border:none;
    }
    .looking-for-box{
        text-align: center;
        margin-bottom: 20px;
    }
    .service-1{
        border:none;
    }
}

